import * as React from "react";

const GoogleTagManager = (): JSX.Element => {
  return (
    <>
      {/*<script data-category="analytics" data-service="Google Analytics">*/}
      {/*  {`*/}
      {/*        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var*/}
      {/*        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
      {/*        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
      {/*        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}')*/}
      {/*      `}*/}
      {/*</script>*/}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe 
                      src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}>" 
                      height="0" 
                      width="0"
                      style="display:none;visibility:hidden">
                      </iframe>
            `,
        }}
      />
    </>
  );
};
export default GoogleTagManager;
