import "vanilla-cookieconsent/dist/cookieconsent.css";

import { GatsbyBrowser } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import { ConfigCookieConsent } from "./src/modules/CookieConsent/CookieConsent";
import GoogleTagManager from "./src/modules/CookieConsent/GoogleTagManager";

export const wrapPageElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  useEffect(() => {
    CookieConsent.run(ConfigCookieConsent);
    document.documentElement.classList.add("cc--darkmode");
  }, []);
  return (
    <>
      <GoogleTagManager />
      {element}
    </>
  );
};
