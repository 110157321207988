import { CookieConsentConfig } from "vanilla-cookieconsent";

import { LANGUAGE } from "../../../../company/settings/translations";
import cookiesEs from "../../translations/es_ES/cookies.json";

export const pushDatalayer = (event: string): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (dataLayer) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataLayer.push({
      event,
    });
  }
};

type Consent = Record<string, boolean>;
const onConsentChange = () => {
  const cookies = document.cookie.split("; ").reduce((cookies, item) => {
    const [name, value] = item.split("=");
    cookies[name] = value;
    return cookies;
  }, {});

  if (!cookies?.cc_cookie) throw new Error("No cookie consent found");
  const consent: Consent = {};
  const parsedCookie = JSON.parse(decodeURIComponent(cookies.cc_cookie));
  parsedCookie.categories.forEach((category) => {
    consent[category] = true;
  });

  const consentMode = {
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
    ad_storage: consent.analytics || consent.marketing ? "granted" : "denied",
    ad_personalization: consent.analytics || consent.marketing ? "granted" : "denied",
    ad_user_data: consent.analytics || consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    personalization: consent.preferences ? "granted" : "denied",
  };
  gtag("consent", "update", consentMode);
  if (dataLayer) {
    dataLayer.push({ event: "consentModeChange", consentMode });
  }
  // recargar la painga
  window.location.reload();
};

export const ConfigCookieConsent: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      flipButtons: true,
      equalWeightButtons: true,
    },
    preferencesModal: {
      layout: "box",
      flipButtons: false,
      equalWeightButtons: true,
    },
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {},
  },
  language: {
    default: LANGUAGE.ES,
    autoDetect: "document",
    translations: {
      es: cookiesEs,
    },
  },
  onFirstConsent: ({ cookie }) => {
    onConsentChange();
  },
  onChange: ({ cookie, changedCategories, changedServices }) => {
    onConsentChange();
  },
  // onConsent: ({ cookie }) => {
  //   const grantedCategories: Consent = {};
  //   cookie.categories.forEach((category) => {
  //     grantedCategories[category] = true;
  //   });
  //
  //   onConsentChange(grantedCategories);
  // },
};
